import React, { useEffect, useRef, useState } from 'react';
import { useSocket } from '../wsprovider';
import { useLocation } from "react-router-dom";

interface SignalMessage {
    from: string;
    type: 'offer' | 'answer' | 'ice-candidate';
    payload: RTCSessionDescriptionInit | RTCIceCandidate;
}

interface RoomProps {
    roomId: string;
}

const Room: React.FC<RoomProps> = ({ roomId }) => {
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);
    const [remoteStreams, setRemoteStreams] = useState<MediaStream[]>([]);
    const peerConnections = useRef<Record<string, RTCPeerConnection>>({});
    const { sockets } = useSocket();
    const location = useLocation();

    const [teamId] = useState(() => {
        const segments = location.pathname.split('/').filter(Boolean);
        return segments[1];
    });

    const config: RTCConfiguration = {
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
        ],
    };

    useEffect(() => {
        const socket = sockets?.[teamId];

        // Подключаем пользователя к комнате
        socket?.emit('join-room', roomId);

        // Запрашиваем только аудиопоток
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then((stream) => {
                setLocalStream(stream);

                socket?.on('signal', (message: SignalMessage) => handleSignal(message, socket));
                socket?.on('user-disconnected', handleUserDisconnected);
            })
            .catch((error) => {
                console.error('Ошибка доступа к аудиоустройствам:', error);
            });

        return () => {
            // Очищаем соединения и сокет
            Object.values(peerConnections.current).forEach((pc) => pc.close());
            socket?.disconnect();
        };
    }, [roomId, sockets, teamId]);

    const handleSignal = (message: SignalMessage, socket: any) => {
        const { from, type, payload } = message;

        if (type === 'offer') {
            const pc = createPeerConnection(from, socket);
            pc.setRemoteDescription(new RTCSessionDescription(payload as RTCSessionDescriptionInit));
            pc.createAnswer().then((answer) => {
                pc.setLocalDescription(answer);
                socket.emit('signal', {
                    to: from,
                    type: 'answer',
                    payload: answer,
                });
            });
        } else if (type === 'answer') {
            peerConnections.current[from]?.setRemoteDescription(
                new RTCSessionDescription(payload as RTCSessionDescriptionInit)
            );
        } else if (type === 'ice-candidate') {
            peerConnections.current[from]?.addIceCandidate(new RTCIceCandidate(payload as RTCIceCandidateInit));
        }
    };

    const handleUserDisconnected = (userId: string) => {
        if (peerConnections.current[userId]) {
            peerConnections.current[userId].close();
            delete peerConnections.current[userId];
            setRemoteStreams((streams) =>
                streams.filter((stream) => stream.id !== userId)
            );
        }
    };

    const createPeerConnection = (userId: string, socket: any): RTCPeerConnection => {
        const pc = new RTCPeerConnection(config);

        if (localStream) {
            localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
        }

        pc.ontrack = (event) => {
            setRemoteStreams((prevStreams) => [...prevStreams, event.streams[0]]);
        };

        pc.onicecandidate = (event) => {
            if (event.candidate) {
                socket.emit('signal', {
                    to: userId,
                    type: 'ice-candidate',
                    payload: event.candidate,
                });
            }
        };

        peerConnections.current[userId] = pc;
        return pc;
    };

    return (
        <div>
            <h1>Комната: {roomId}</h1>
            <div>
                {remoteStreams.map((stream, index) => (
                    <audio
                        key={index}
                        ref={(ref) => {
                            if (ref) {
                                ref.srcObject = stream;
                                ref.play();
                            }
                        }}
                        controls
                    />
                ))}
            </div>
        </div>
    );
};

export default Room;
