import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ChatMessage from "./Chat_message";
import { MessageType, ResponseModel, ThreadType } from "../../../libraries/Models";
import { useSocket } from "../../../wsprovider";
import { useLocation } from "react-router-dom";
import React from "react";
import Checkbox from "../../Checkbox";
import Modal from "../../Modal";
import { ChannelProcesses } from "../header/ChannelSettings";
import Dropdown from "../../Dropdown";

export type ChatMainViewType = {
  className?: string;
  threadId: string;
  teamId?: string;
  onSendMessage: (message: MessageType) => void;
  incomingMessage: MessageType | undefined;
  allMessages: MessageType[] | undefined;
  ext_thread_id: string | undefined;
  showInMobile?: boolean
};
interface BotEvent {
  threadId: string;
  name: string;
}

const LIMIT = 20;

const ChatMainView: React.FC<ChatMainViewType> = ({ className, onSendMessage, incomingMessage, showInMobile, allMessages, ext_thread_id }) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { createMessageAction, decodedTokens, users, getMessagesAction, sockets, createThreadAction, channels } = useSocket();
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [message, setMessage] = useState("");
  const [selectedChannelId, setSelectedChannelId] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [skip, setSkip] = useState(0);
  const location = useLocation();
  const [botTyping, setBotTyping] = useState<string | null>(null);
  const [teamId, channelId, threadId] = useMemo(() => {
    const segments = location.pathname.split('/').filter(Boolean);
    return [segments[1], segments[2], segments[3]];
  }, [location.pathname]);
  const prevThreadId = useRef<string | undefined>();
  const isScrolledToBottom = useRef(true);

  useEffect(() => {
    setTimeout(() => {
      if (messagesContainerRef.current && showInMobile) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, 100);
  }, [showInMobile])
  useEffect(() => {
    if (prevThreadId.current !== threadId && threadId && sockets) {
      prevThreadId.current = threadId;
      setIsLoading(true);
      setMessages([])
      setSkip(0)
      getMessagesAction(threadId, teamId, 0, LIMIT).then((response) => {
        const initialMessages = response || [];
        setMessages(initialMessages);
        setSkip(initialMessages.length);
        setHasMoreMessages(initialMessages.length === LIMIT);
        setIsLoading(false);

        // Scroll to bottom after loading the first batch
        setTimeout(() => {
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
          }
        }, 100);
      });
    }
  }, [sockets, threadId, getMessagesAction, teamId]);
  useEffect(() => {
    if (!incomingMessage) return;

    // Проверка, является ли сообщение новым (например, по ID)
    const isNewMessage = !messages.find((msg) => msg.id === incomingMessage.id);
    if (isNewMessage) {
      setMessages((prev) => [incomingMessage, ...prev]);
      if (isScrolledToBottom.current) {
        // Скролл к последнему сообщению, если пользователь находится внизу
        setTimeout(() => {
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
          }
        }, 100);
      }
    }
  }, [incomingMessage, messages]);
  const handleScroll = useCallback(() => {
    if (!messagesContainerRef.current) return;

    const container = messagesContainerRef.current;
    const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;

    // Устанавливаем флаг, если скролл находится внизу
    isScrolledToBottom.current = isAtBottom;

    if (isLoading || !hasMoreMessages || isAtBottom) return;

    const scrollThreshold = container.scrollHeight * 0.1; // 10% from the top
    if (container.scrollTop <= scrollThreshold) {
      const previousScrollHeight = container.scrollHeight;
      setIsLoading(true);
      getMessagesAction(threadId, teamId, skip, LIMIT).then((response) => {
        if (response && response.length > 0) {
          setMessages((prevMessages) => [...prevMessages, ...response]);
          setSkip((prevSkip) => prevSkip + response.length);
          setHasMoreMessages(response.length === LIMIT);
          setTimeout(() => {
            if (messagesContainerRef.current) {
              // Adjust scrollTop to maintain the previous scroll position
              messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - previousScrollHeight;
            }
          }, 0);
        } else {
          setHasMoreMessages(false);
        }
        setIsLoading(false);
      });
    }
  }, [isLoading, hasMoreMessages, threadId, teamId, skip, getMessagesAction]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const sendMessage = useCallback(async () => {
    if (!message || !teamId) return;
    const newMessage = {
      id: messages.length + 1,
      thread_id: String(threadId),
      type: "text",
      content: message,
      created_at: Date.now(),
      created_by: decodedTokens?.[teamId]?.user_id ?? ''
    };
    setMessages((prevMessages) => [newMessage, ...prevMessages]);
    setMessage("");

    // Scroll to bottom after sending a new message
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, 100);
    const m = await createMessageAction(newMessage, teamId);
    setMessages(prev => prev.map(item => {
      if (item.id === m.id) {
        return m.message
      } else {
        return item
      }
    }))
  }, [message, teamId, messages.length, threadId, decodedTokens, createMessageAction]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && message !== "" && teamId) {
      e.preventDefault();
      sendMessage();
    }
  }, [message, teamId, sendMessage]);

  const formatDate = (date: Date): string => {
    const today = new Date();
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }
    return `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
  };
  useEffect(() => {
    const socket = sockets?.[String(teamId)];
    if (!socket) return;

    const handleBotThink = (event: BotEvent) => {
      console.log(JSON.stringify(event))
      if (event.threadId === threadId) {
        setBotTyping(event.name);
      }
    };

    const handleBotStop = (event: BotEvent) => {
      if (event.threadId === threadId) {
        setBotTyping(null);
      }
    };

    socket.on('bot_think', handleBotThink);
    socket.on('bot_stop', handleBotStop);

    return () => {
      socket.off('bot_think', handleBotThink);
      socket.off('bot_stop', handleBotStop);
    };
  }, [sockets, teamId, threadId]);
  const getUserKey = (message: MessageType) => {
    return message.created_by === 'no_user' ? `no_user-${message.type}` : message.created_by;
  };

  const getGroupedMessagesByDate = useMemo(() => {
    const groupedMessagesByDate: { date: string, messagesByUser: MessageType[][] }[] = [];
    let currentDate = "";
  
    // Реверсируем порядок сообщений для сортировки от новых к старым
    const reversedMessages = [...messages.filter(item => item.thread_id === threadId)].reverse();
  
    const messagesByUser = reversedMessages.reduce((acc, message) => {
      const lastGroup = acc[acc.length - 1];
      const messageUserKey = getUserKey(message);
  
      if (lastGroup) {
        const lastMessage = lastGroup[lastGroup.length - 1];
        const lastMessageUserKey = getUserKey(lastMessage);
  
        if (messageUserKey === lastMessageUserKey) {
          lastGroup.push(message);
        } else {
          acc.push([message]);
        }
      } else {
        acc.push([message]);
      }
  
      return acc;
    }, [] as MessageType[][]);
  
    for (const group of messagesByUser) {
      const messageDate = new Date(group[0].created_at);
      const formattedDate = messageDate.toISOString().split('T')[0];
      const displayDate = formatDate(messageDate);
  
      if (formattedDate !== currentDate) {
        groupedMessagesByDate.push({ date: displayDate, messagesByUser: [group] });
        currentDate = formattedDate;
      } else {
        groupedMessagesByDate[groupedMessagesByDate.length - 1].messagesByUser.push(group);
      }
    }
  
    return groupedMessagesByDate;
  }, [messages]);
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    const textarea = e.target;
    textarea.style.height = "0";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Ограничение по высоте 150px
  };
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [messageToCreate, setMessageToCreateThread] = useState<MessageType | undefined>(undefined)
  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>, message: MessageType) => {
    event.preventDefault(); // Отключить стандартное контекстное меню
    setPopupPosition({ x: event.pageX, y: event.pageY });
    setPopupVisible(true);
    setMessageToCreateThread(message)
  };
  useEffect(() => {
    if (popupVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popupVisible]);
  const handleOutsideClick = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setPopupVisible(false);
    }
  };
  const [thread, setThread] = useState<ThreadType | undefined>(undefined);
  const [isOpen, setOpenModal] = useState(false);

  const openCreateThreadModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const createThread = useCallback(() => {
    const channel = channels?.find(item => String(item.id) === String(selectedChannelId));
    if (!channel) {return}
    const process = (JSON.parse(channel?.settings || '{}').processes as ChannelProcesses[] || [])[0];
    const newThread: ThreadType = {
      channel_id: Number(selectedChannelId),
      parent_thread_id: threadId,
      ext_thread_id: "null",
      title: thread?.title || "",
      type: "chat",
      is_hidden: thread?.is_hidden === true,
      status: channel?.is_process ? (process ? process.id : '') : ""
    };
    createThreadAction(newThread, teamId, messageToCreate);
    setOpenModal(false);
    setThread(undefined);
  }, [selectedChannelId, thread, createThreadAction, teamId, channels]);
  const handleOnChangeThreadTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setThread((prev) => ({ ...prev!, title: value }));
  };
  return (
    <div className={`flex flex-1 flex-col lg:h-[90vh] h-full grow bg-white lg:mr-4 lg:ml-4 ${className}`}>
      <div 
        ref={messagesContainerRef} 
        className="flex flex-1 flex-col h-full overflow-y-scroll"
      >
        {isLoading && <div className="loader">Loading...</div>}
        
        {getGroupedMessagesByDate.length === 0 ? (
          <div className="flex h-full w-full justify-center items-center">
            <img className="flex" src="/emptyChat.png" alt="emptyChat" height={500} width={500} />
          </div>
        ) : (
          getGroupedMessagesByDate.map((dateGroup, index) => (
            <div key={index}>
              <div className="sticky top-0 z-10 flex justify-center py-1 text-[#8D84B0] text-sm font-semibold">
                <div className="flex w-28 h-8 bg-white rounded-lg text-center justify-center items-center">
                  {dateGroup.date}
                </div>
              </div>
              {dateGroup.messagesByUser.map((group, idx) => (
                <div key={idx}>
                  {group.map((message, messageIdx) => (
                    <div onContextMenu={(event) => handleRightClick(event, message)}>
                      <ChatMessage
                      key={message.id}
                      id={`message-${message.id}`}
                      message={message.content}
                      username={users?.find((user) => user.id === message.created_by && user.team_id === teamId)?.nicname || ''}
                      img={message.type === "bot" ? '/bot_avatar.jpg' : message.type === 'operator' ? '/operator_avatar.jpg' : messageIdx === group.length - 1 ? '/emptyAvatar.png' : undefined}
                      showAvatar={messageIdx === group.length - 1}
                      senderId={Number(message.created_by)}
                      prevSenderId={messageIdx > 0 ? Number(group[messageIdx - 1].created_by) : undefined}
                      timestamp={message.created_at}
                    />
                    </div>
                    
                  ))}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
      {popupVisible && (
        <div
          ref={popupRef}
          className="absolute bg-white border border-gray-300 rounded shadow-lg p-2 z-50"
          style={{ top: popupPosition.y, left: popupPosition.x }}
        >
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={openCreateThreadModal}
          >
            Создать
          </button>
        </div>
      )}
      {botTyping && (
        <div className="sticky top-0 z-10 flex justify-start py-1 text-sm font-semibold text-blue-500">
          {botTyping} is typing...
        </div>
      )}
      <div className="flex flex-row h-auto w-full mb-0 mr-5 justify-start items-center space-x-2">
        <textarea
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="flex-1 h-6 max-h-[150px] max-w-[90%] resize-none rounded-[10px] border border-[#C4BFD4] text-lg px-2 py-1 m-4 overflow-y-auto"
          placeholder="Type message"
          value={message}
        />
        <button disabled={!!botTyping} onClick={sendMessage} className="flex w-11 bg-transparent h-6 justify-start items-center cursor-pointer border-none">
          <img className="" alt="" width={24} height={30} src={'/send.svg'} />
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={() => setOpenModal(false)}>
        <div className="flex flex-col m-5">
          <div className="text-[24px] font-normal font-['Roboto'] text-[#797199]">Создание треда</div>
          <input
            className="w-full border-none outline-none bg-ghostwhite self-stretch h-[43px] px-3 py-1 box-border font-h1 text-sm text-darkgray my-2 rounded-md"
            placeholder="Название треда"
            type="text"
            value={thread?.title || ""}
            onChange={handleOnChangeThreadTitle}
          />
          <Dropdown
                options={channels?.map(item => ({ id: String(item.id), name: item.name })) || []}
                selectedId={selectedChannelId}
                onSelect={(id: React.SetStateAction<string>) => setSelectedChannelId(id)}
            />
          {users?.find((i) => Number(i.id) === Number(decodedTokens?.[teamId]?.user_id))?.is_admin && (
            <Checkbox label="Приватный" onChange={(isChecked) => setThread((prev) => ({ ...prev!, is_hidden: isChecked }))} />
          )}
          <button className="flex justify-center items-center text-sm text-white bg-blue p-3 rounded-md mt-3 border-none" onClick={createThread}>
            Создать
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ChatMainView;
