import React, { useCallback, useEffect, useState } from "react";
import { useSocket } from "../../../wsprovider";
import Checkbox from "../../Checkbox";
import Modal from "../../Modal";
import { ChannelType, ThreadType } from "../../../libraries/Models";
import BurgerContainer from "./BurgerContainer";
import { ChannelProcesses } from "./ChannelSettings";
import Room from "../../WebrtcRoom";

export type HeaderMainViewType = {
  className?: string;
  currentThreadTitle?: string;
  onClick?: (id?: number) => void;
  showConversations?: boolean;
  selectedSidebarItemId?: number;
  addNewThread?: (thread: ThreadType) => void;
  hideThreadInfo?: (isHidden: boolean) => void;
  showContainer3: boolean;
  showContainer4: boolean;
  setShowContainer4: (isShow: boolean) => void;
  setShowContainer3: (isShow: boolean) => void;
  teamId: string;
  channelId?: string;
  threadId?: string;
};

const HeaderMainView: React.FC<HeaderMainViewType> = ({
  channelId,
  threadId,
  className,
  currentThreadTitle = "",
  onClick,
  setShowContainer4,
  setShowContainer3,
  selectedSidebarItemId,
  addNewThread,
  teamId,
  hideThreadInfo,
  showContainer3,
  showContainer4,
}) => {
  const { channels, sockets, decodedTokens, createThreadAction, currentThread, users } = useSocket();
  const [thread, setThread] = useState<ThreadType | undefined>(undefined);
  const [isOpen, setOpenModal] = useState(false);
  const [isOpenCallRoom, setOpenCallRoom] = useState(false)
  const [isOpenViewMembersModal, setOpenViewMembersModal] = useState(false);
  const [isOpenBurger, setOpenBurgerModal] = useState(false);
  const [isHiddenThreadInfo, setHiddenThreadInfo] = useState(false);
  const [channel, setChannel] = useState(channels?.find(item => item.id === selectedSidebarItemId));

  const openCreateThreadModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const openViewMembersModal = useCallback(() => {
    setOpenViewMembersModal(true);
  }, []);

  useEffect(() => {
    if (channels) {
      setChannel(channels?.find(item => String(item.id) === String(selectedSidebarItemId)));
    }
  }, [channels, selectedSidebarItemId]);

  const createThread = useCallback(() => {
    const channel = channels?.find(item => String(item.id) === String(selectedSidebarItemId));
    const process = (JSON.parse(channel?.settings || '{}').processes as ChannelProcesses[] || [])[0];
    const newThread: ThreadType = {
      channel_id: selectedSidebarItemId!,
      parent_thread_id: undefined,
      ext_thread_id: "null",
      title: thread?.title || "",
      type: "chat",
      is_hidden: thread?.is_hidden === true,
      status: channel?.is_process ? (process ? process.id : '') : "",
    };
    createThreadAction(newThread, teamId, undefined);
    setOpenModal(false);
    setThread(undefined);
  }, [selectedSidebarItemId, thread, createThreadAction, teamId, channels]);

  const openBurger = () => {
    setOpenBurgerModal(true);
  };

  const handleOnChangeThreadTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setThread((prev) => ({ ...prev!, title: value }));
  };

  const handleBackBtnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleHideThreadInfo = () => {
    setHiddenThreadInfo(!isHiddenThreadInfo);
    if (hideThreadInfo) {
      hideThreadInfo(!isHiddenThreadInfo);
    }
  };

  return (
    <header className={`w-full flex pr-4 h-[70px] bg-white drop-shadow-lg text-black fixed top-0 z-20 ${className}`}>
      <div className={`${(showContainer3 || showContainer4) && 'max-lg:hidden'} flex w-[94px] min-w-[94px] justify-center items-center`}>
        <button onClick={openBurger} className="bg-[#4E4963] h-full border-none w-full flex justify-center items-center">
          <img alt="Menu" width={31} height={24} src={'/burger.svg'} />
        </button>
      </div>

      {channelId && (
        <div className={`${(showContainer3 || showContainer4) && 'max-lg:hidden'} flex items-center justify-between w-full lg:w-[400px]`}>
          <div className="flex flex-col justify-center text-[#797199] ml-3">
            <div className="pt-3 text-sm font-normal font-['Roboto'] lg:text-[24px] transition-all duration-300 ease-in-out">Groups</div>
            <div className="pb-3 mt-2 text-[14px] font-normal font-['Roboto'] ">All</div>
          </div>
          <div className="flex">
            <img onClick={openViewMembersModal} className="m-3" alt="View Members" width={30} height={24} src={'/stream-meembers-btn.svg'} />
            <img onClick={openCreateThreadModal} className="m-3" alt="Add Thread" width={22} height={22} src={'/add-thread-btn.svg'} />
          </div>
        </div>
      )}

      {threadId && (
        <div className={`flex items-center justify-between px-4 transition-all duration-300 ${showContainer3 && 'max-lg:flex-1'} ${showContainer4 ? 'lg:min-w-[500px] lg:flex-1' : 'lg:flex-grow'} ${!showContainer3 && 'max-lg:hidden'} ${(showContainer3 && showContainer4) && 'max-lg:hidden'}`}>
          <button onClick={() => { setShowContainer3(false); handleBackBtnClick(); }} className="hidden max-lg:flex px-2 bg-white py-1 rounded">
            <img alt="Back" width={22} height={22} src={'/arrow-left.svg'} />
          </button>
          <h2 className="text-lg text-center w-full">{currentThreadTitle}</h2>
          <div>
            <button onClick={() => setShowContainer4(!showContainer4)} className="px-2 py-1 rounded border-none">
              <img onClick={handleHideThreadInfo} alt="Open Info" width={31} height={24} src={'/openinfo.svg'} />
            </button>
            {/* <button onClick={() => setOpenCallRoom(true)} className="px-2 py-1 rounded border-none">
              <img onClick={handleHideThreadInfo} alt="Call room" width={31} height={24} src={'/phone.png'} />
            </button> */}
          </div>
         
        </div>
      )}

      {channelId && threadId && (
        <div className={`flex items-center justify-between px-4 transition-all duration-300 ${showContainer4 ? 'lg:w-[300px] max-lg:flex-1' : 'hidden'}`}>
          <h2 className="text-lg text-center w-full">Thread info</h2>
          <button onClick={() => setShowContainer4(!showContainer4)} className="bg-green-500 border-none px-2 py-1 rounded lg:hidden">
            <img onClick={handleHideThreadInfo} alt="Close Info" width={31} height={24} src={'/openinfo.svg'} />
          </button>
        </div>
      )}

      <Modal isOpen={isOpen} onClose={() => setOpenModal(false)}>
        <div className="flex flex-col m-5">
          <div className="text-[24px] font-normal font-['Roboto'] text-[#797199]">Создание треда</div>
          <input
            className="w-full border-none outline-none bg-ghostwhite self-stretch h-[43px] px-3 py-1 box-border font-h1 text-sm text-darkgray my-2 rounded-md"
            placeholder="Название треда"
            type="text"
            value={thread?.title || ""}
            onChange={handleOnChangeThreadTitle}
          />
          {users?.find((i) => Number(i.id) === Number(decodedTokens?.[teamId]?.user_id))?.is_admin && (
            <Checkbox label="Приватный" onChange={(isChecked) => setThread((prev) => ({ ...prev!, is_hidden: isChecked }))} />
          )}
          <button className="flex justify-center items-center text-sm text-white bg-blue p-3 rounded-md mt-3 border-none" onClick={createThread}>
            Создать
          </button>
        </div>
      </Modal>

      <Modal isOpen={isOpenViewMembersModal} onClose={() => setOpenViewMembersModal(false)}>
        <div className="flex flex-col m-5">
          <div className="text-[24px] font-normal font-['Roboto'] text-[#797199] mb-4">Members of channel</div>
          {users?.map((member) => (
            <div key={member.fullname || member.nicname || member.username} className="flex justify-between items-center mb-2">
              <div className="flex flex-1 items-center space-x-2">
                <img src={`https://i.pravatar.cc/150?u=${member.fullname || member.nicname || member.username}`} alt={member.fullname || member.nicname || member.username} className="w-8 h-8 rounded-full" />
                <span className="text-sm cursor-pointer">{member.fullname || member.nicname || member.username}</span>
              </div>
              <span className="flex text-sm text-gray-500 cursor-pointer">{channel?.admins?.includes(member.id) ? "admin" : "member"}</span>
            </div>
          ))}
        </div>
      </Modal>

      <Modal isOpen={isOpenBurger} onClose={() => setOpenBurgerModal(false)}>
        <BurgerContainer />
      </Modal>
      <Modal isOpen={isOpenCallRoom} onClose={() => setOpenCallRoom(false)}>
        <Room roomId={threadId!}/>
      </Modal>
    </header>
  );
};

export default HeaderMainView;
