'use client'
import React, { createContext, useContext, useEffect, useState, ReactNode, useCallback, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';
import { ChannelType, MessageType, ResponseModel, ThreadType, UserType } from './libraries/Models';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiddenChannels } from './components/chat_components/header/BurgerContainer';
import useLocalStorage from './localstorage';

interface SocketContextProps {
  sockets: Record<string, Socket> | null;
  decodedTokens: Record<string, DecodedToken> | null;
  channels: ChannelType[] | undefined;
  users: UserType[] | undefined;
  threads: ThreadType[] | undefined;
  currentThread: ThreadType | undefined;
  newMessage: MessageType | undefined;
  messages: MessageType[] | undefined;
  tokens?: Token[];
  newThread?: ThreadType
  createThreadAction: (thread: ThreadType, teamId: string, createdByMessage?: MessageType) => void;
  createMessageAction: (message: MessageType, teamId: string) => Promise<{ message: MessageType; id: number }>;
  createChannelAction: (channel: ChannelType, teamId: string) => void;
  updateChannelNameAction: (channelId: number, newName: string, teamId: string) => void;
  updateChannelIsPublicAction: (channelId: number, isPublic: boolean, teamId: string) => void;
  updateChannelIsProcessAction: (channelId: number, isProcess: boolean, teamId: string) => void;
  updateChannelSettingsAction: (channelId: number, updatedSettings: string, teamId: string) => Promise<ChannelType[] | undefined>;
  updateThreadStatusAction: (threadId: string, status: string, teamId: string) => void;
  toggleHideChannel: (id: number, teamId: string) => void;
  updateUserInfoAction: (userData: Partial<UserType>, teamId: string) => void;
  getSelartiCompanies: (id: number, teamId: string) => Promise<{ id: string, name: string }[]>;
  getSelartiTasks: (id: number, teamId: string, companyId: string) => Promise<{ id: string, name: string }[]>;
  getMessagesAction: (threadId: string, teamId: string, skip: number, limit?: number) => Promise<MessageType[]>;
  readThread: (id: string) => void;
  getThreads: (channelId: number, limit: number, offset: number) => Promise<ThreadType[] | undefined>;
  loading: boolean; // Добавлено для состояния загрузки
}

interface DecodedToken {
  user_id: string;
  team_id: string;
  sub: string;
  name: string;
  exp: number;
  iat: number;
  role: string;
  is_admin: boolean;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export interface Token {
  team_id: string;
  team_name: string;
  token: string;
}

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sockets, setSockets] = useState<Record<string, Socket> | null>(null);
  const [decodedTokens, setDecoded] = useState<Record<string, DecodedToken> | null>(null);
  const [channels, setChannels] = useState<ChannelType[] | undefined>(undefined);
  const [threads, setThreads] = useState<ThreadType[] | undefined>(undefined);
  const [users, setUsers] = useState<UserType[] | undefined>(undefined);
  const [hiddenChannelsLocal, setHiddenChannelsToLocal] = useLocalStorage<HiddenChannels[] | undefined>('hiddenChannels', []);
  const [newThread, setNewThread] = useState<ThreadType | undefined>()
  const [messages, setMessages] = useState<MessageType[] | undefined>(undefined);
  const [newMessage, setNewMessage] = useState<MessageType | undefined>(undefined);
  const [currentThread, setCurrentThread] = useState<ThreadType | undefined>(undefined);
  const [loading, setIsLoading] = useState(true); // Состояние загрузки
  const location = useLocation();
  const [teamId, channelId, threadId] = useMemo(() => {
    const segments = location.pathname.split('/').filter(Boolean);
    return [segments[1], segments[2], segments[3]];
  }, [location.pathname]);
  const [tokens, setTokens] = useState<Token[]>();
  const navigate = useNavigate();

  function getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([$?*|{}()\[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  useEffect(() => {
    const data = getCookie('data');
    const tokens = localStorage.getItem('authTokens');
    
    if (!data && !tokens) {
      window.location.href = `https://auth.pbx.team`;
      return;
    }
    const parsedTokens = JSON.parse(tokens || data || '[]');

    if (JSON.stringify(parsedTokens) !== JSON.stringify(tokens)) {
      setTokens(parsedTokens);
      console.log('Tokens after reload: ', parsedTokens);
    }
  }, []);

  useEffect(() => {
    if (!tokens?.length) return;
    console.log("init socket");
    let socketConnections: Record<string, Socket> = {};
    let decodeds: Record<string, DecodedToken> = {};
    let connectedCount = 0;
    tokens.forEach((teamId) => {
      const socketInstance = io(process.env.REACT_APP_SOCKET_URL || 'https://s2.pbx.team', {
        auth: {
          token: teamId.token
        }
      });

      socketConnections[teamId.team_id] = socketInstance;

      socketInstance.on('connect', () => {
        connectedCount++;
        if (connectedCount === 1) {
          setIsLoading(false); // Отключаем загрузку, когда подключен хотя бы один сокет
        }
        console.log('socket connected');
        socketInstance.emit('get_channels', (response: ResponseModel) => {
          const mappedChannels = response.channels?.map((item) => {
            const hideItem = hiddenChannelsLocal?.find(
              (i) => i.id === item.id && i.teamId === item.team_id
            );
            return hideItem ? { ...item, is_hidden: true } : item;
          });

          setChannels((prev) => {
            const updatedChannels = (prev || []).map((channel) => {
              const newChannel = mappedChannels?.find((item) => item.id === channel.id);
              return newChannel ? { ...channel, ...newChannel } : channel;
            });
            const newChannels = mappedChannels?.filter(
              (item) => !updatedChannels.some((channel) => channel.id === item.id)
            );
            return [...updatedChannels, ...(newChannels || [])];
          });
        });

        socketInstance.emit('get_users', (response: ResponseModel) => {
          if (response.success && response.users) {
            setUsers((prev) => {
              const updatedUsers = [...(prev || [])];
              response.users.forEach((newUser) => {
                const existingUserIndex = updatedUsers.findIndex((user) => user.id === newUser.id);
                if (existingUserIndex !== -1) {
                  updatedUsers[existingUserIndex] = { ...updatedUsers[existingUserIndex], ...newUser };
                } else {
                  updatedUsers.push(newUser);
                }
              });
              return updatedUsers;
            });
          }
        });
      });

      socketInstance.on('new_message', (response: ResponseModel) => {
        setNewMessage(response.message);
        setMessages((prev) => [...(prev || []), response.message]);
        setThreads((prevThreads) => {
          return prevThreads?.map((item) =>
            (String(item.id) === String(response.message.thread_id) || String(item.ext_thread_id) === String(response.message.thread_id))
              ? {
                  ...item,
                  is_unread: Number(threadId) !== Number(item.id),
                  last_message_text: response.message.content,
                  last_message_user_id: response.message.created_by,
                  last_message_created_at: response.message.created_at,
                }
              : item
          );
        });
      });

      socketInstance.on('selarti_threads', (response: ResponseModel) => {
      
        if (response.threads && String(response.threads[0]?.channel_id) === String(channelId)) {
          setThreads(prevThreads => {
            // Создаем Map для хранения уникальных тредов по их `id` и `ext_thread_id`
            const threadMap = new Map(
              (prevThreads || []).map(thread => [thread.id, thread])
            );
      
            // Обрабатываем каждый новый тред из ответа
            response.threads?.forEach(newThread => {
              // Проверяем, существует ли тред с таким `id` или `ext_thread_id`
              const existingThread = Array.from(threadMap.values()).find(
                thread => thread.id === newThread.id || thread.ext_thread_id === newThread.id
              );
      
              if (existingThread) {
                // Если тред найден, обновляем его
                threadMap.set(existingThread.id, { ...existingThread, ...newThread });
              } else {
                // Если тред не найден, добавляем его как новый
                threadMap.set(newThread.id, newThread);
              }
            });
      
            // Преобразуем Map обратно в массив и сортируем по `last_message_created_at`
            return Array.from(threadMap.values()).sort((a, b) =>
              Number(a.last_message_created_at) - Number(b.last_message_created_at)
            );
          });
        }
      });
      
      

      // Подписка на события
      socketInstance.on('new_message', (response: ResponseModel) => {
        setNewMessage(response.message);
        setMessages((prev) => [...(prev || []), response.message]);
        setThreads((prevThreads) => {
          return prevThreads?.map((item) =>
            (String(item.id) === String(response.message.thread_id) || String(item.ext_thread_id) === String(response.message.thread_id))
              ? {
                  ...item,
                  is_unread: Number(threadId) !== Number(item.id),
                  last_message_text: response.message.content,
                  last_message_user_id: response.message.created_by,
                  last_message_created_at: response.message.created_at,
                }
              : item
          );
        });
      });
  
      // Другие подписки на события
      socketInstance.on('thread_created', (response: ResponseModel) => {
        setThreads((prev) => [response.thread!, ...(prev || [])]);
      });
  
      socketInstance.on('thread_changed', (response: ResponseModel) => {
        setThreads((prev) => [response.thread!, ...(prev || [])]);
        setNewThread(response.thread!)
      });
  
      socketInstance.on('channel_created', (response: ResponseModel) => {
        setChannels((prev) => [response.channel, ...(prev || [])]);
      });
  
      const decoded = jwtDecode<DecodedToken>(teamId.token);
      decodeds[teamId.team_id] = decoded;
    });
  
    setDecoded(decodeds);
    setSockets(socketConnections);
  
    // Очистка сокетов при размонтировании
    return () => {
      Object.values(socketConnections).forEach((socket) => socket.disconnect());
    };
  }, [tokens]); // Добавьте hiddenChannelsLocal в зависимости
  
  const createThreadAction = (thread: ThreadType, teamId: string, createByMessage?: MessageType) => {
    sockets?.[teamId]?.emit(createByMessage ? 'create_thread_by_message' : 'create_thread', thread, createByMessage, (response: ResponseModel) => {
      if (response.success) {
        setThreads((prev) => [response.thread!, ...(prev || [])]);
        setNewThread(response.thread!)
      }
    });
  };

  const createChannelAction = (channel: ChannelType, teamId: string) => {
    const socket = sockets?.[teamId];
    socket?.emit('create_channel', channel, (response: ResponseModel) => {
      if (response.success) {
        setChannels((prev) => [response.channel, ...(prev || [])]);
      }
    });
  };
  
  const createMessageAction = (message: MessageType, teamId: string): Promise<{ message: MessageType; id: number }> => {
    return new Promise((resolve, reject) => {
      sockets?.[teamId]?.emit('send_message', message, (response: ResponseModel) => {
        const newThreads = threads?.map((item) => {
          if (String(item?.id) === String(message?.thread_id)) {
            return { 
              ...item, 
              last_message_text: message.content, 
              last_message_user_id: message.created_by, 
              last_message_created_at: message.created_at 
            };
          }
          return item;
        });
        setThreads(newThreads);
        
        if (response?.message) {
          resolve({ message: response.message, id: message.id }); // Возвращаем response.message
        } else {
          reject(new Error('Response message not found'));
        }
      });
    });
  };
  
  

  const updateChannelNameAction = (channelId: number, newName: string, teamId: string) => {
    sockets?.[teamId]?.emit('update_channel_name', channelId, newName, (response: ResponseModel) => {
      if (response.success) {
        const updatedChannels = channels?.map((channel) => channel.id === channelId ? { ...channel, name: newName } : channel);
        setChannels(updatedChannels);
      }
    });
  };

  const updateChannelIsPublicAction = (channelId: number, isPublic: boolean, teamId: string) => {
    sockets?.[teamId]?.emit('update_is_public', channelId, isPublic, (response: ResponseModel) => {
      if (response.success) {
        const updatedChannels = channels?.map((channel) => channel.id === channelId ? { ...channel, is_public: isPublic } : channel);
        setChannels(updatedChannels);
      }
    });
  };

  const updateChannelIsProcessAction = (channelId: number, isProcess: boolean, teamId: string) => {
    sockets?.[teamId]?.emit('update_is_process', channelId, isProcess, (response: ResponseModel) => {
      if (response.success) {
        const updatedChannels = channels?.map((channel) => channel.id === channelId ? response.channel : channel);
        setChannels(updatedChannels);
      }
    });
  };
  const getThreads = async (channelId: number, limit: number, offset: number): Promise<ThreadType[] | undefined> => {
    return new Promise((resolve, reject) => {
        sockets?.[teamId ? teamId.toString() : '']?.emit(
            'get_threads',
            Number(channelId),
            { limit, skip: offset },
            (response: ResponseModel) => {
                if (!response.success) {
                    return reject(undefined);
                }
                
                setThreads((prevThreads) => {
                  // const existingIds = new Set((prevThreads || []).map(thread => thread.id));
                  // const newThreads = (response.threads || []).filter(thread => !existingIds.has(thread.id));
                  if (!response.success) {return prevThreads}
                  // Массив уникальных тредов
                  const uniqueThreads = [
                      ...(prevThreads || []),
                      ...response.threads!
                  ].sort((a, b) => {
                      const aTimestamp = a.last_message_created_at ? Number(a.last_message_created_at) : Infinity;
                      const bTimestamp = b.last_message_created_at ? Number(b.last_message_created_at) : Infinity;
                      
                      return aTimestamp - bTimestamp;
                  });
                  
                  return uniqueThreads;
              });
                
                resolve(response.threads);
            }
        );
    });
};
  const updateChannelSettingsAction = async (channelId: number, updatedSettings: string, teamId: string): Promise<ChannelType[] | undefined> => {
    return new Promise((resolve, reject) => {
      sockets?.[teamId]?.emit('update_channel_settings', channelId, updatedSettings, (response: ResponseModel) => {
        console.log("request settings1: " + JSON.stringify(response.channel))
        if (response.success) {
          setChannels((prevChannels) => {
            if (!prevChannels) return prevChannels;
            return prevChannels.map((channel) =>
              channel.id === channelId
                ? response.channel
                : channel
            );
          });
          resolve([response.channel]);
          return;
        }
        reject();
      });
    });
  };
  
  const updateThreadStatusAction = (threadId: string, status: string, teamId: string) => {
    sockets?.[teamId]?.emit('update_thread_status', threadId, status, (response: ResponseModel) => {
      if (response.success) {
        const updatedThreads = threads?.map((thread) =>
          thread.id === String(threadId) ? { ...thread, status: status } : thread
        );
        setCurrentThread(response.thread)
        setThreads(updatedThreads);
      }
    });
  };
  const updateUserInfoAction = (userData: Partial<UserType>, teamId: string) => {
    sockets?.[teamId]?.emit('update_user', userData, (response: ResponseModel) => {
      if (response.success) {
        // Обновляем информацию о пользователе в локальном состоянии
        const updatedUsers = users?.map((user) => user.id === response.user.id ? { ...user, ...response.user } : user);
        setUsers(updatedUsers);
      }
    });
  };
  const getSelartiCompanies = (id: number, teamId: string): Promise<{ id: string, name: string }[]> => {
    return new Promise((resolve, reject) => {
      sockets?.[teamId]?.emit('getSelartiCompanies', id, (response: ResponseModel) => {
        if (response.success && response.companies) {
          resolve(response.companies.map((company: any) => ({ id: company.id, name: company.name })));
        } else {
          reject(new Error('Failed to fetch Selarti companies'));
        }
      });
    });
  };

  // Функция для получения задач Selarti
  const getSelartiTasks = (id: number, teamId: string, companyId: string): Promise<{ id: string, name: string }[]> => {
    return new Promise((resolve, reject) => {
      sockets?.[teamId]?.emit('getSelartiTasks', id, companyId, (response: ResponseModel) => {
        if (response.success && response.tasks) {
          resolve(response.tasks.map((task: any) => ({ id: task.id, name: task.name })));
        } else {
          reject(new Error('Failed to fetch Selarti tasks'));
        }
      });
    });
  };
  const getMessagesAction = (threadId: string, teamId: string, skip: number, limit: number = 20): Promise<MessageType[]> => {
    return new Promise((resolve, reject) => {
      sockets?.[teamId]?.emit('get_messages', threadId, { limit, skip }, (response: ResponseModel) => {
        if (response.success) {
          resolve(response.messages || []);
        } else {
          resolve([]);
        }
      });
    });
  };
  
  const toggleHideChannel = useCallback((id: number, teamId: string) => {
    const updatedChannels = channels?.map((item) => item.id === id && item.team_id === teamId ? { ...item, is_hidden: !item.is_hidden } : item);
    setChannels([...updatedChannels!]);
    const item = hiddenChannelsLocal?.find(i => i.id === id && i.teamId === teamId);
    if (item) {
      const filtered = hiddenChannelsLocal?.filter(i => i.id !== id || i.teamId !== teamId);
      setHiddenChannelsToLocal(filtered);
      return;
    }
    const newHide = [...hiddenChannelsLocal || [], { id, teamId }];
    setHiddenChannelsToLocal(newHide);
  }, [channels, hiddenChannelsLocal, setHiddenChannelsToLocal]);
  const readThread = (id: string) => {
    const updatedThreads = threads?.map((thread) =>
      String(thread.id) === String(id) ? { ...thread, is_unread: false } : thread
    );
    setThreads(updatedThreads);
  }
  return (
    <SocketContext.Provider value={{
      sockets,
      decodedTokens,
      channels,
      threads,
      users,
      currentThread,
      newMessage,
      messages,
      tokens,
      newThread,
      createThreadAction,
      createMessageAction,
      createChannelAction,
      updateChannelNameAction,
      updateChannelIsPublicAction,
      updateChannelIsProcessAction,
      updateChannelSettingsAction,
      updateThreadStatusAction,
      updateUserInfoAction,
      toggleHideChannel,
      getMessagesAction,
      getSelartiCompanies,
      getSelartiTasks,
      readThread,
      getThreads,
      loading
    }}>
      {children}
    </SocketContext.Provider>
  );
};
